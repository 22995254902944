import Vue from 'vue';
import Router from 'vue-router';
import Print from 'vue-print-nb'
Vue.use(Print)
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/',
            component: () => import(/* webpackChunkName: "home" */ '../components/common/Home.vue'),
            meta: { title: '自述文件' },
            children: [
                {
                    path: '/userTransfer',
                    component: () => import(/* webpackChunkName: "userTransfer" */ '../components/page/userTransfer.vue'),
                    meta: { title: '用户转职' }
                },
                {
                    path: '/home',
                    component: () => import(/* webpackChunkName: "home" */ '../components/page/home.vue'),
                    meta: { title: '华立园大门-人脸重置控制面板' }
                },
                {
                    path: '/faceReset',
                    component: () => import(/* webpackChunkName: "faceReset" */ '../components/page/faceReset.vue'),
                    meta: { title: '人脸采集批量重置' }
                },
                {
                    path: '/exportEnterRecord',
                    component: () => import(/* webpackChunkName: "exportEnterRecord" */ '../components/page/exportEnterRecord.vue'),
                    meta: { title: '进出记录导出' }
                },
                {
                    path: '/exportOutRecord',
                    component: () => import(/* webpackChunkName: "exportOutRecord" */ '../components/page/exportOutRecord.vue'),
                    meta: { title: '学生外出记录导出' }
                },
                {
                    path: '/exportOutStaffRecord',
                    component: () => import(/* webpackChunkName: "exportOutStaffRecord" */ '../components/page/exportOutStaffRecord.vue'),
                    meta: { title: '学生外出申请列表' }
                },
                {
                    path: '/exportInviteRecord.',
                    component: () => import(/* webpackChunkName: "exportInviteRecord" */ '../components/page/exportInviteRecord.vue'),
                    meta: { title: '邀请来访记录导出' }
                },
                {
                    path: '/schoolManage.',
                    component: () => import(/* webpackChunkName: "schoolManage" */ '../components/page/schoolManage.vue'),
                    meta: { title: '二级学院管理' }
                },
                {
                    path: '/collegeManage.',
                    component: () => import(/* webpackChunkName: "collegeManage" */ '../components/page/collegeManage.vue'),
                    meta: { title: '班级管理' }
                },
                {
                    path: '/classManage.',
                    component: () => import(/* webpackChunkName: "classManage" */ '../components/page/classManage.vue'),
                    meta: { title: '学生管理' }
                },
                {
                    path: '/schoolAdmin.',
                    component: () => import(/* webpackChunkName: "schoolAdmin" */ '../components/page/schoolAdmin.vue'),
                    meta: { title: '学校审核员' }
                },
                {
                    path: '/collegeAdmin.',
                    component: () => import(/* webpackChunkName: "collegeAdmin" */ '../components/page/collegeAdmin.vue'),
                    meta: { title: '二级院校审核员' }
                },
                {
                    path: '/outStrategy.',
                    component: () => import(/* webpackChunkName: "outStrategy" */ '../components/page/outStrategy.vue'),
                    meta: { title: '外出策略管理' }
                },
                // {
                //     path: '/exportStuApplyList.',
                //     component: () => import(/* webpackChunkName: "exportStuApplyList" */ '../components/page/exportStuApplyList.vue'),
                //     meta: { title: '学生外出申请列表' }
                // },
                // {
                //     path: '/exportLocationRecord.',
                //     component: () => import(/* webpackChunkName: "exportLocationRecord" */ '../components/page/exportLocationRecord.vue'),
                //     meta: { title: '位置上报记录导出' }
                // },

                // {
                //     // vue-schart组件
                //     path: '/charts',
                //     component: () => import(/* webpackChunkName: "chart" */ '../components/page/BaseCharts.vue'),
                //     meta: { title: 'schart图表' }
                // },
                // {
                //     path: '/404',
                //     component: () => import(/* webpackChunkName: "404" */ '../components/page/404.vue'),
                //     meta: { title: '404' }
                // },
                // {
                //     path: '/403',
                //     component: () => import(/* webpackChunkName: "403" */ '../components/page/403.vue'),
                //     meta: { title: '403' }
                // },
            ]
        },
        {
            path: '/login',
            component: () => import(/* webpackChunkName: "login" */ '../components/page/Login.vue'),
            meta: { title: '登录' }
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
});
